
            @import "@starlight-bd/frontend-library/src/style/style.scss";
            

:deep() {
  &.heartbeat {
    z-index: 9999 !important;
  }
  .s-modal {
    margin-top: 30vh !important;
  }
  .s-handle-icon.s-icon {
    display: none !important;
  }
  .s-modal-header,
  .s-modal-footer {
    border: none !important;
  }
  .s-modal-header {
    padding-top: map-get($spacers, '8') !important;
    padding-bottom: map-get($spacers, '8') !important;
    .s-modal-title,
    button.s-btn {
      display: none;
    }
  }
}
.wrap {
  display: flex;
}
.icon {
  color: map-get($info-colors, 'warning');
  flex-grow: 0;
  padding-right: map-get($spacers, '8');
}
.message {
  flex-grow: 1;
}
.title {
  line-height: 30px;
  font-size: 18px;
  font-weight: $font-weight-bold;
}
